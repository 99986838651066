.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.arrow_box {
  color: #fff;
  background: linear-gradient(252.4deg, #F7BE2D -63.99%, #F78E2D 87.82%) !important;
}

#style1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100px;
  height: 100px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

#style1::-webkit-color-swatch {
  border-radius: 15px;
  border: none;
}

#style1::-moz-color-swatch {
  border-radius: 15px;
  border: none;
}
.apexcharts-tooltip.apexcharts-theme-light{
  /* border:5px solid red !important; */
  background: none !important;
  border: none !important;
}
.apexcharts-tooltip{
  border-radius: 5px;
    box-shadow: none !important;
    font-size: 14px;

   
}
.wrapper {
  width: 100vw;
  background-color: rgba(245, 225, 248, 0.541);

}

@media(max-width: 600px) {

  .calendar,
  .icons {
    scale: 0.85;
  }
}

@media(max-width: 400px) {

  .calendar,
  .icons {
    scale: 0.75;
  }
}

.icons {
  padding: 20px 0px;
}

.weeks {
  display: grid;
  text-align: center;
  grid-template-columns: repeat(7, 1fr);
}

.fs {
  font-size: 23px;
  font-weight: 700;
  text-align: center;
  color: rgba(178, 139, 255, 0.541);
}


li,
.li {

  text-align: center;
  font-size: 22px;
margin: 5px;
  padding: 1vw;
  cursor: pointer;
  list-style: none;
  font-weight: 700;
}

.li:hover {
  box-shadow: 0px 0px 0px 1px grey;
  border-radius: 10px;
}

.calendar {

  background-color: white;
  border-radius: 10px;
  min-width: 500px;
  width: 50vw;

  padding: 1rem;
  box-shadow: 0px 0px 10px 0px rgb(202, 202, 202);
}

.icons {
  justify-content: center !important;
}

.next-date,
.prev-date {
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  color: rgba(178, 139, 255, 0.541);
}

.current-date {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  color: rgb(153, 0, 255);
}

.inactive {
  color: rgba(178, 139, 255, 0.541) !important;
}

.active {
  color: white;
  border-radius: 10px;
  background-color: rgb(152, 44, 224) !important;
}

.dote {
  scale: 1.5;
  overflow: hidden;
  height: 25px;
  width: 100%;
  margin-top: -10px;
}

.orange {
  color: #FF8900;
}

.violet {
  color: #00B283;
}
.sky {
  color: #0095FF;
}

.center {
  text-align: center;
}
.slider-task{
  width: 100% !important;
}


/* asadasdsadaf */
.react-calendar {
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__navigation button {
  color: #d4a713;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

abbr[title] {
  text-decoration: none;
}

/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #d4a713;
  border-radius: 6px;
}

.react-calendar__tile--now {
  background: #d4a71333;
  border-radius: 6px;
  font-weight: bold;
  color: #d4a713;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #d4a71333;
  border-radius: 6px;
  font-weight: bold;
  color: #d4a713;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}

.react-calendar__tile--active {
  background: #d4a713;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #d4a713;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background: #f8f8fa;
  color: #d4a713;
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #d4a713;
  color: white;
}

.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #d4a713;
  color: white;
}
.height-slider{
  height: 270px !important;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.7);
position: absolute;
left: 3%;
right: 3%;
top: 3%;
bottom: 3%;
padding: 4rem !important;
border-radius: 20px;
z-index: 50000;
color:#ffffff;
display: flex;
justify-content: center;
align-items: center;
font-size: 3rem;

}

#text{
  /* position: absolute;
  top: 50%;
  left: 50%; */
  font-size: 50px;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}
.c1{
  /* border: 1px solid red; */
  /* padding: 10px;
  margin: 5px; */
  position: relative;
}

/* plan */


.styled-title {
  background: -webkit-linear-gradient(300deg, #FF5901 45%, #fe2d66 50%, #734699 55%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.3;
}


#planos .box:hover {
  background-image: linear-gradient(170deg, #ff5a01, #fe2d66, #734699);
  color: #fff;
}
#planos .box:hover .styled-title {
  background: -webkit-linear-gradient(300deg, #FFF 45%, #FFF 50%, #FFF 55%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#planos .box:hover .wide-button-small .wp-block-button__link {
  background-color: #fff!important;
  color: #FF5901!important;
}
#planos .box .styled-title strong {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}
.box {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.wp-block-columns {
  align-items: normal !important;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap !important;
  margin-bottom: 1.75em;
}
.payment-button{
width:fit-content;
padding: 10px 15px;
font-size: 15px;
font-weight: 600;
background-color: rgb(0, 0, 0);
color: white;
border: none;
border-radius: 100vmax;
}
.end{
display: flex;
justify-content: end;
  align-items: end;
}
.payment-form{
  padding: 15px !important;
  border-radius: 10px;

  margin-top: 15px;
  box-shadow: 0px 0px 4px 0px rgb(185, 185, 185);
}
.google-btn{
  background-color: #fff;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 50%;
  scale:0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
}
.apple-btn{
  background-color: #000;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
}

.registerContainer{
  background-color: #fff !important;
  margin-top: 30px !important;
}

.protectedOutlet{
  display: block;
}

.mobileWebBlock{
  display: none;
}

@media (max-width:400px) {
  .registerContainer{
    margin-top: 0px !important;
    height: 100vh !important;
  }
  .protectedOutlet{
    display: none;
  }
  .mobileWebBlock{
    display: block;
  }
}

